export const Banners = [
     {
          name:"Car Rental",
          images:{
               hr:"https://s3.eu-north-1.amazonaws.com/clickrwanda.s3.com/banners/Need-car.gif",
               vr:"https://s3.eu-north-1.amazonaws.com/clickrwanda.s3.com/banners/aska-vr.png"
          },
          link: "https://wa.me/+250727559173"
     },
     {
          name:"Visit My Venue",
          images:{
               hr:"https://s3.eu-north-1.amazonaws.com/clickrwanda.s3.com/banners/visitmyvenue_hr-banner.gif",
               vr:"https://s3.eu-north-1.amazonaws.com/clickrwanda.s3.com/banners/visitmyvenue-banner-vr.gif"
          },
          link: "https://visitmyvenue.com"
     }
]

export const TopDealsSidebanners = [
     {
          name: 'banner',
          image: 'https://s3.eu-north-1.amazonaws.com/clickrwanda.s3.com/banners/aska-vr.png',
          link: 'https.//wa.me/message/ATUUNFXHTEMWM1'
     },
     {
          name: 'banner',
          image: 'https://s3.eu-north-1.amazonaws.com/clickrwanda.s3.com/banners/visitmyvenue-banner-vr.gif',
          link:'https://visitmyvenue.com'
     }
]

export const FeaturedAdsSidebanners = [
     {
          name: 'banner',
          image: 'https://s3.eu-north-1.amazonaws.com/clickrwanda.s3.com/banners/visitmyvenue-banner-vr.gif',
          link:'https://visitmyvenue.com'
     },
     {
          name: 'banner',
          image: 'https://s3.eu-north-1.amazonaws.com/clickrwanda.s3.com/banners/aska-vr.png',
          link: 'https://wa.me/message/ATUUNFXHTEMWM1'
     },
     
]

export const DefaultSideBanner = {
     name: "Advert Here",
     image: "https://s3.eu-north-1.amazonaws.com/clickrwanda.s3.com/banners/asideAd.png",
     link: "https://www.clickrwanda.com/payment-plans/banners"
}